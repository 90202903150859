import { useState } from "react";
import "./App.css";

function App() {
  const [active, setActive] = useState(null);

  const option = {
    Diversity:
      "Founders and Coders strives to bring diversity to the programme and that ressonates with me. Having worked and lived in different countries, I have a deep respect for culture and the importance of diversity. It brings about connectedness, humanity and celebration of each unique background and perspective. It will be a fun and humble experience learning from each other at Founders and Coders.",
    "The Impact":
      "What excites me about technology is being part of an industry that is widely used and increasingly integrated within our lives. A recent example I could think of is how a situation such as Covid quickly changed the way we work and integrate technology so that we can work from home and stay connected. Technology solves problems that could bring real impact to the society and that is what excites me about working in this industry.",
    "The Culture":
      "The tech community is built on a culture of support and empowerment. Since I have been on the coding journey, I have personally received a lot of support that I have not seen anywhere else. For example, I have benefited from free resources such as FreeCodeCamp, YouTube and Code First Girls; I have also received help by talented developers in the community that I do not personally know. This culture is very empowering and one that I would like to be part of so that I can uplift others around me.",
  };

  const onClickHandler = (e) => {
    setActive(e);
  };

  return (
    <div className="App">
      <main>
        <div id="showcase">
          <div id="showcase-text">
            <img src="mee.jpg" alt="Bonnie" className="showcaseImg" />
            <h1 id="titleName">Bonnie Wong</h1>
            <p>Hello and welcome to my portfolio.</p>
            <p>Click below to specific section or scroll down to read more!</p>
            <div id="showcase-btn">
              <a href="#about" className="btn btn_0">
                About Me
              </a>
              <a href="#reason" className="btn btn_1">
                The Reason
              </a>
              <a href="#contact" className="btn btn_2">
                Contact Me
              </a>
            </div>
          </div>
        </div>

        <div id="about">
          <div className="aboutText">
            <h2>About Me</h2>
            <p>
              <span className="largeLetters"> Hello, </span>my name is Bonnie. I
              grew up in both Hong Kong and New Zealand, and now I reside in the
              UK. I was trained as a qualified accountant for years before
              career switched to software development
            </p>
            <p>
              I am someone who is constantly looking for ways to expand my
              skills outside of work. Over the years, I found myself drawn to
              the tech community. There was something quite empowering about the
              tech community and the people around it that really interested me
              and led me to explore further in this space. I attended many
              informative tech events, but one in particular, Ada Lovelace Day
              event, left a mark on me and I thought to myself, what if I could
              be like those people in the community knowledgeable and empower
              others?
            </p>
            <p>
              At the end of last year, I took a leap of faith and resigned from
              my stable job to pursue coding. Since then I've been learning and
              have been very fortunate to meet many talented people who have
              generously helped me with my journey. I'd love to have the
              opportunity to join Founders and Coders's spring cohort 2021, be
              part of the community that supports and enables others along the
              way.
            </p>
          </div>
          <div className="aboutText">
            <p>
              <span className="largeLetters">I enjoy...</span>
            </p>
            <p className="show">
              <small>Hover over the image to reveal...</small>
            </p>
            <div className="aboutimages">
              <div className="imgcontainer">
                <img src="yoga.jpg" className="aboutimg" alt="yoga" />
                <div className="overlay">
                  <div className="overlayText">Yoga</div>
                </div>
              </div>
              <div className="imgcontainer">
                <img src="code.jpg" className="aboutimg" alt="coding" />
                <div className="overlay">
                  <div className="overlayText">Coding</div>
                </div>
              </div>
              <div className="imgcontainer">
                <img src="draw.jpg" className="aboutimg" alt="drawing" />
                <div className="overlay">
                  <div className="overlayText">Drawing</div>
                  <div className="show">Art account: </div>
                  <button className="acctButton show">
                    <a href="https://www.instagram.com/bonnie___bee/">
                      bonnie___bee
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="reason" className="reason">
          <h2>The Reason</h2>
          <div id="button">
            <button
              className={`btn btn_0 ${active === "Diversity" && "active"}`}
              onClick={() => onClickHandler("Diversity")}
            >
              Diversity
            </button>
            <button
              className={`btn btn_1 ${active === "The Impact" && "active"}`}
              onClick={() => onClickHandler("The Impact")}
            >
              The Impact
            </button>
            <button
              className={`btn btn_2 ${active === "The Culture" && "active"}`}
              onClick={() => onClickHandler("The Culture")}
            >
              The Culture
            </button>
          </div>
          <div className="speech-bubble">
            <p id="speechBubbleText">
              {active
                ? option[active]
                : "Click on the options above to find out more!"}
            </p>
          </div>
          <img src="thinking.png" alt="thinking" />
        </div>
      </main>

      <footer>
        <div id="contact" className="footerContainer">
          <div className="footerText">
            <h2>Contact Me</h2>
            <p>Email: waiyeewong89@gmail.com</p>
            <p>
              <a className="footerLinks" href="https://github.com/Bonnie-wy">
                {" "}
                GitHub: <span className="line">Bonnie-wy </span>
              </a>
            </p>
            <p>
              <a
                className="footerLinks"
                href="https://www.linkedin.com/in/bonnie-wong-5b53558a/"
              >
                {" "}
                LinkedIn: <span className="line">Bonnie Wong </span>
              </a>
            </p>
          </div>
          <div>
            <img
              src="me.jpg"
              alt="thank you for coming"
              className="footerImg"
            />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
